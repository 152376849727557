.ant-layout-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;

  img {
    height: 48px;
  }
}

.lower-icon {
  vertical-align: text-top;
}

.text-center {
  margin-left: 0.5rem;
}