.LegalNoticePage{
    height: 100%;
}

.new-file{
    color: red;
}

.sticky-row{
    position: sticky;
    bottom: 0;
    padding: 1rem 0 1rem 0;
    background-color: rgba(255, 255, 255, 0.7);
}

.iframe-height{
    width: 55%;
    height: 100%;
}