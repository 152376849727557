.CGUPage{
  height: 100%;
}

.new-file{
  color: red;
}

.sticky-row{
  position: sticky;
  bottom: 0;
  padding: 0.7rem 0 0.7rem 0;
  background-color: rgba(255, 255, 255, 0.7);
}