.ApplicationPage {
  overflow: hidden;

  &-Container {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1em;
    margin-bottom: 1em;
  }

  &-Name {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-OrganizationName {
    margin-right: 1rem;
  }

  &-UsersTable-ActionColumn {
    width: 7em;
    text-align: center;
  }
}

.text-middle {
  display: flex;
  align-items: center;
}