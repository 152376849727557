.ApplicationList {
  &-Organisation {
    background-color: #1677ff;
    border-radius: 50%;
    display: inline-block;
    margin-right: -0.3rem;
    width: 2rem;
    height: 2rem;
    line-height: 1.7rem;
    color: #fff;
    text-align: center;
    border: 2px #fff solid;
  }
}
.TableAutoComplete {
  position: relative;

  .autocomplete-overlay {
    display: flex;
    align-items: center;
  }
}
