.AuthPage {
  background-image: url('../../../public/images/back_auxaview.jpeg');
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-position: left top;
  background-repeat: no-repeat;
  background-size: cover;

  &-logoContainer {
    width: 18rem;
    display: block;
    margin-bottom: 1rem;

    .logo {
      width: 100%;
    }

  }

  &-formContainer {
    width: 24rem;
    background-color: #ffffff;
    padding: 2rem 2rem;
    border-radius: 1rem;
  }

  
}